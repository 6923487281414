<template>
  <div class="histories">
    <page-header
      :title="title"
      :subtitle="subtitle"
      :show-action="true"
      :show-funding="true"
      :is-wallet="true"
    />
    <div class="page_content">
      <div
        class="content_header cursor-pointer mt-4"
        @click="showRechargeHistory = !showRechargeHistory"
      >
        <h3
          class="float-right cursor-pointer w-5 h-5 pl-1 rounded-full icon-container bg-red"
        >
          <svg-icon
            name="ic-down-arrow"
            class="w-3 h-3"
            fill-current
          />
        </h3>
        <h3 class="header uppercase text-lg font-normal subtitle-wallet">
          {{ $t('transactionHistory') }}
        </h3>
      </div>
      <transition name="fade">
        <div
          v-if="showRechargeHistory"
          class="wallet-history-list mt-4 slide-top"
        >
          <div class="wallet-history-list-header font-bold flex w-full bg-white p-3 rounded text-base">
            <div class="flex-one w-1/3 uppercase">
              {{ $t('rechargeDate') }}
            </div>
            <div class="flex-one w-1/3 uppercase">
              {{ $t('payment') }}
            </div>
            <div class="flex-one w-1/3 uppercase">
              {{ $t('rechargeAmount') }}
            </div>
            <div class="flex-one w-1/5 uppercase">
              {{ $t('invoiceAction') }}
            </div>
            <div class="flex-one text-white w-1/7">
              <div class="flex-one text-white w-1/7 invisible">
                <svg-icon
                  name="ic-more"
                  class="w-4 h-4"
                  fill-current
                />
              </div>
            </div>
          </div>
          <wallet-history-table
            v-for="(item, key) in history.slice().reverse()"
            :key="item + key"
            :recharge="item"
          />
        </div>
      </transition>
      <div
        class="content_header mt-4 cursor-pointer"
        @click="showTransactionHistory = !showTransactionHistory"
      >
        <h3
          class="float-right cursor-pointer w-5 h-5 pl-1 rounded-full icon-container bg-red"
        >
          <svg-icon
            name="ic-down-arrow"
            class="w-3 h-3"
            fill-current
          />
        </h3>
        <h3 class="header uppercase text-lg font-normal subtitle-wallet">
          {{ $t('realTransactionHistory') }}
        </h3>
      </div>
      <transition name="fade">
        <div
          v-if="showTransactionHistory"
          class="invoice-history-list mt-4"
        >
          <div class="invoice-history-list-header font-bold flex w-full bg-white p-3 rounded text-base">
            <div class="flex-one w-1/3 uppercase">
              {{ $t('invoiceDate') }}
            </div>
            <div class="flex-one w-1/3 uppercase">
              {{ $t('desc') }}
            </div>
            <div class="flex-one w-1/3 uppercase">
              {{ $t('invoiceAmount') }}
            </div>
            <div class="flex-one w-1/5 uppercase">
              {{ $t('invoiceAction') }}
            </div>
            <div class="flex-one text-white w-1/7">
              <div class="flex-one text-white w-1/7 invisible">
                <svg-icon
                  name="ic-more"
                  class="w-4 h-4"
                  fill-current
                />
              </div>
            </div>
          </div>
          <invoice-history-list
            v-for="(item, pos) in retract.slice().reverse()"
            :key="item+pos"
            :pos="pos"
            :transaction="item"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import PageHeader from '../../components/helper/rema-components/page-header'
import WalletHistoryTable from '../../components/helper/rema-components/wallet-history-table'
import InvoiceHistoryList from '../../components/helper/rema-components/invoice-history-list'
import firebase from 'firebase'
import { firebaseDb } from '../../main'
import { firebaseRef } from '../../rema-function/firebaseRef'

export default {
  name: 'HomeHistories',
  components: { InvoiceHistoryList, WalletHistoryTable, PageHeader },
  props: {
    msg: String
  },
  data () {
    return {
      title: this.$t('wallet'),
      subtitle: this.$t('walletSubtitle'),
      showRechargeHistory: true,
      rechargeList: [],
      showTransactionHistory: true,
      showFunding: false,
      solde: 0,
      wallet: {},
      history: [],
      retract: []
    }
  },
  computed: {},
  methods: {
    closeRecharge (answer) {
      this.showFunding = answer
    }
  },
  firebase () {
    return {
      wallet: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid),
      history: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('history'),
      retract: firebaseDb.ref(firebaseRef.wallets).child(firebase.auth().currentUser.uid).child('outHistory')
    }
  },
  created () {
    // alert()
    // alert(firebase.auth().currentUser.uid.toString())
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/sass/variables";
  .subtitle-wallet {
    color: $Grey-background-Rema;
  }
  .icon-container {
    padding-top: .1rem;
    background-color: $Grey-background-Rema;
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transition: opacity .5s;
}
  .wallet-history-list-header {
    color: $Dark-Text-Rema;
  }
</style>
