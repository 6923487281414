<template>
  <div class="wallet-history-table mt-1">
    <div class="wallet-history-list-header font-light flex w-full bg-white p-3 rounded text-base">
      <div class="flex-one w-1/3 ">
        {{ timestampToDate(recharge.date) }}
      </div>
      <div class="flex-one w-1/3 ">
        {{ rechargeMean(recharge.type) }}
      </div>
      <div class="flex-one w-1/3 ">
        {{ recharge.amount }} €
      </div>
      <div
        class="flex-one w-1/5 text-green cursor-pointer"
        @click="downloadInvoice('pdf-section' + recharge.ref)"
      >
        {{ $t('invoiceDownload') }}
      </div>
      <div class="flex-one text-white w-1/7">
        <div class="flex-one text-white w-1/7 invisible">
          <svg-icon
            name="ic-more"
            class="w-4 h-4"
            fill-current
          />
        </div>
      </div>
    </div>
    <div
      :id="'pdf-section' + recharge.ref"
      class="pdf-section pt-10"
    >
      <div class="rema-logo pt-10">
        <svg-icon
          name="ic-rema"
          class="w-64"
          original
        />
      </div>
      <div class="mt-2 rema-details">
        <div class="float-right text-left">
          <h3 class="font-normal text-sm uppercase">
            {{ $t('creditNom') }}{{ generateKeyN(recharge.ref) }}
          </h3>
          <h3 class="font-normal text-sm uppercase mt-1">
            Date: {{ timestampToDate(recharge.date) }}
          </h3>
        </div>
        <div class="details-left">
          <h3 class="font-normal text-sm">
            REMA MEDICAL TECHNOLOGIES
          </h3>
          <h3 class="font-normal text-sm">
            RCCM RB/COT/19 A 46336,
          </h3>
          <h3 class="font-normal text-sm">
            C/982 Hinde sud, 09 BP 0573
          </h3>
          <h3 class="font-normal text-sm">
            Cotonou, Benin
          </h3>
        </div>
      </div>
      <div class="bg-white p-4 mt-10">
        <div class="facture-to">
          <h3 class="font-normal text-sm">
         {{ $t('deliveredTo') }}:
          </h3>
          <h3 class="font-normal text-sm">
            {{ user.fullname }}
          </h3>
        </div>
        <div class="invoce-item border mt-6">
          <div class="flex title-section border-b">
            <div class="w-1/4 flex-1 text-left uppercase p-4 border-r">
              {{ $t('designation') }}
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              Date
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              {{ $t('unitPrice') }}
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              {{ $t('quantity') }}
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 ">
              {{ $t('totalRecharge') }}
            </div>
          </div>
          <div class="flex content-section">
            <div class="w-1/4 flex-1 text-left uppercase p-4 border-r">
              {{ rechargeMean(recharge.type) }}
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              {{ timestampToDate(recharge.date) }}
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              {{ recharge.amount }} €
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
              01
            </div>
            <div class="flex-1 w-1/5 text-left uppercase p-4 ">
              {{ recharge.amount }}
            </div>
          </div>
        </div>
      </div>
      <div class="total-section mt-4 p-6">
        <h3 class="float-right">
          {{ recharge.amount }}€
        </h3>
        <h3 class="">
         {{ $t('totalRecharge') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
export default {
  name: 'WalletHistoryTable',
  props: {
    recharge: {
      type: Object,
      default: function () {
        return {
          date: new Date().getTime(),
          type: 'visa',
          amount: 10000000000
        }
      }
    },
    keyN: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      user: {}
    }
  },
  methods: {
    timestampToDate (timestamp) {
      let obj = new Date(timestamp)
      return obj.toLocaleDateString('en-GB')
    },
    rechargeMean (type) {
      if (type === 'visa') {
        return this.$t('bankCard')
      } else {
        return this.$t('remaCode')
      }
    },
    downloadInvoice (divName) {
      let invoiceContent = document.getElementById(divName).innerHTML
      let originalContent = document.body.innerHTML
      document.body.innerHTML = invoiceContent
      window.print()
      document.body.innerHTML = originalContent
      window.location.reload(1)
    },
    generateKeyN (ref) {
      if (ref < 10) {
        return '0000' + ref
      } if (ref < 100) {
        return '000' + ref
      } else {
        return '00' + ref
      }
    }
  },
  firebase () {
    return {
      user: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .pdf-section {
    display: none;
  }
</style>
