<template>
  <div class="invoice-history-list mt-1">
    <div class="invoice-history-list-header font-light flex w-full bg-white p-3 rounded text-base">
      <div class="flex-one w-1/3 ">
        {{ timestampToDate(transaction.createdAt) }}
      </div>
      <div class="flex-one w-1/3 uppercase">
        {{ $t('invoice') }}
      </div>
      <div class="flex-one w-1/3 ">
        {{ relativeCampaign.postCout }} €
      </div>
      <div @click="downloadInvoice('pdf-section' + transaction.createdAt)"  class="flex-one w-1/5 cursor-pointer invoiceDownload">
        {{ $t('invoiceDownload') }}
      </div>
      <div class="flex-one text-white w-1/7 invisible cursor-pointer">
        <svg-icon
          name="ic-more"
          class="w-4 h-4"
          original
        />
      </div>
    </div>
    <div
    :id="'pdf-section' + transaction.createdAt"
    class="pdf-section pt-10"
    >
    <div class="rema-logo pt-10">
      <svg-icon
        name="ic-rema"
        class="w-64"
        original
      />
    </div>
    <div class="mt-2 rema-details">
      <div class="float-right text-left">
        <h3 class="font-normal text-sm uppercase">
          {{ $t('invoice') }}: {{ generateKeyN(transaction.ref) }}
        </h3>
        <h3 class="font-normal text-sm uppercase mt-1">
          Date: {{ timestampToDate(transaction.createdAt) }}
        </h3>
      </div>
      <div class="details-left">
        <h3 class="font-normal text-sm">
          REMA MEDICAL TECHNOLOGIES
        </h3>
        <h3 class="font-normal text-sm">
          RCCM RB/COT/19 A 46336,
        </h3>
        <h3 class="font-normal text-sm">
          C/982 Hinde sud, 09 BP 0573
        </h3>
        <h3 class="font-normal text-sm">
          Cotonou, Benin
        </h3>
      </div>
    </div>
    <div class="bg-white p-4 mt-10">
      <div class="facture-to">
        <h3 class="font-normal text-sm">
          {{ $t('invoicdeAt') }}:
        </h3>
        <h3 class="font-normal text-sm">
          {{ user.fullname }}
        </h3>
      </div>
      <div class="invoce-item border mt-6">
        <div class="flex title-section border-b">
          <div class="w-1/4 flex-1 text-left uppercase p-4 border-r">
            {{ $t('designation') }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            {{ $t('durationInDay') }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            {{ $t('unitPrice') }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            {{ $t('quantity') }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 ">
            {{ $t('debitedAmount') }}
          </div>
        </div>
        <div class="flex content-section">
          <div class="w-1/4 flex-1 text-left uppercase p-4 border-r">
            <span class="text-sm">{{ $t('pubCampaign') }}</span> <br/>
            {{ relativeCampaign.title }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            {{ relativeCampaign.postDuree }}
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            {{ relativeCampaign.postCout }} €
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 border-r">
            01
          </div>
          <div class="flex-1 w-1/5 text-left uppercase p-4 ">
            {{ relativeCampaign.postCout }} €
          </div>
        </div>
      </div>
    <div class="total-section mt-4 p-6">
      <h3 class="float-right">
        {{ relativeCampaign.postCout }}€
      </h3>
      <h3 class="">
        {{ $t('totalDebited') }}
      </h3>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import { firebaseDb } from '../../../main'
import { firebaseRef } from '../../../rema-function/firebaseRef'
import firebase from 'firebase'
export default {
  name: 'InvoiceHistoryList',
  props: {
    transaction: {
      type: Object,
      default: function () {
        return {
          date: 0,
          amount: 0
        }
      }
    },
    pos: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      relativeCampaign: {},
      user: {}
    }
  },
  methods: {
    timestampToDate (timestamp) {
      let obj = new Date(timestamp)
      return obj.toLocaleDateString('en-GB')
    },
    generateKeyN (ref) {
      if (ref < 10) {
        return 'RMT0000' + ref
      } if (ref < 100) {
        return 'RMT000' + ref
      } else {
        return 'RMT00' + ref
      }
    },
    downloadInvoice (divName) {
      let invoiceContent = document.getElementById(divName).innerHTML
      let originalContent = document.body.innerHTML
      document.body.innerHTML = invoiceContent
      window.print()
      document.body.innerHTML = originalContent
      window.location.reload(1)
    }
  },
  firebase () {
    return {
      // relativeCampaign: firebaseDb.ref(firebaseRef.mainsAdsFeeds).child(this.transaction.campaignId),
      user: firebaseDb.ref(firebaseRef.users).child(firebase.auth().currentUser.uid)
    }
  },
  created () {
    // var data = null
    let vm = this
    firebaseDb.ref(firebaseRef.mainsAdsFeeds).child(this.transaction.campaignId)
      .once('value', function (snapshot) {
        if (snapshot.exists()) {
          vm.relativeCampaign = snapshot.val()
          console.log(snapshot.val())
        } else {
          firebaseDb.ref(firebaseRef.mainsSMSAdsFeeds).child(vm.transaction.campaignId)
            .once('value', function (snapshotD) {
              if (snapshotD.exists()) {
                vm.relativeCampaign = snapshotD.val()
              }
            })
        }
      })
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .invoiceDownload {
    color: $Green-Rema;
  }
  .pdf-section {
    display: none;
  }
</style>
